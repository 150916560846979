import { createApp, h } from 'vue';
import App from './App.vue';
import { router, metaManager } from './router'; // Ensure the correct path
import store from './store'; // Import the store
import EventBus from '@/eventBus'; // Import the EventBus
import { createHead } from '@vueuse/head';


// Import Bootstrap and other styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';


// Import custom styles
import './deps.js';
import './deps.scss';
import './assets/scss/style.scss';

// Create the Vue app instance
console.log("Creating Vue app instance");

const app = createApp({
    render: () => h(App)
});
const head = createHead();

// Global event bus using mitt
app.config.globalProperties.$emitter = EventBus;

// Use plugins and libraries
app.use(store);
app.use(router);
app.use(metaManager);
app.use(head);

// Mount the Vue app
console.log("Mounting Vue app instance");

app.mount('#v-app');

    // Register the service worker
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
            .then(registration => {
              console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch(err => {
             console.error('Failed to register Service Worker:', err);
          });
       });
     }


   //     fetch('/')
 //   .then(response => {
  //      if (!response.ok) {
    //        throw new Error('Network response was not ok ' + response.statusText);
  //      }
  //      return response;
 //   })
 //   .then(data => console.log('File exists'))
 //   .catch(error => console.error('Error fetching file:', error));
  
