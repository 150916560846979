<template>
  <div class="container-fluid">
    <div :class="$style.footer" id="redhouseFooter">
      <div class="row mt-4">
        <div class="col-sm-6 col-md-3 my-4 text-center">
          <div class="mb-1">redhouse</div>
          <div>we do<span class="ml-3" :class="$style.red" > IT.</span></div>
        </div>
        <div class="col-sm-6 col-md-3 my-4 text-center">
          <div class="mb-1"><router-link class="text-decoration-none" :to="{ name: 'impressum' }">{{ findText(["impressum", "imprint"]) }}</router-link></div>
          <div class="mb-1"><router-link class="text-decoration-none" :to="{ name: 'dataPolicy' }">{{ findText(["datenschutz", "data policy"]) }}</router-link></div>
          <div class="mb-1"><router-link class="text-decoration-none" :to="{ name: 'agb' }">{{ findText(["agb", "tos"]) }}</router-link></div>
        </div>
        <div class="col-sm-6 col-md-3 my-4 text-center">
          <div class="mb-1"><router-link class="text-decoration-none" :to="{ name: 'kontakt' }">{{ findText(["kontakt", "contact"]) }}</router-link></div>
          <div class="mb-1" :class="$style.contact"><a class="text-decoration-none" href="tel:0049221671170">+49 (221) 67117-0</a></div>
          <div class="mb-1" :class="$style.contact"><a class="text-decoration-none" href="mailto:contact@redhouse.de">contact@redhouse.de</a></div>
        </div>
        <div class="col-sm-6 col-md-3 my-4 text-center">
          <div class="mb-1">redhouse GmbH</div>
          <div :class="$style.contact">Carlswerkstraße 11a</div>
          <div :class="$style.contact">51063 Köln</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EventBus from '@/eventBus';

  export default {
    name: 'AppFooter', // Updated to a multi-word name

    data () {
      return {
        footerStyle: "Home"
      }
    },
    computed: {
      currentLang () {
        return this.$store.getters.lang;
      }
    },
    methods: {
      findText (translations) {
        switch (this.currentLang) {
          case "DE":
            return translations[0];
          case "EN":
            return translations[1];
          default:
            return translations[0];
        }
      }
    },
    mounted () {
      EventBus.on('changeActiveSection', (section) => {
        this.footerStyle = section.section;
        let el = document.getElementById("redhouseFooter");
        if (section.section == 'imprint' && screen.width > 700 && document.getElementById("content").offsetHeight < (window.innerHeight - el.offsetHeight - document.getElementById("redhouseHeader").offsetHeight)) {
          el.style.position = "fixed";
          el.style.bottom = "0";
        } else {
          el.style.position = "relative";
          el.style.bottom = null;
        }
      });
    }
  };
</script>

<style lang="scss" module>
  @import '../_variables.scss';

  .footer {
      width: 100%;
    background-color: #f5f5f5;
    font-size: 11pt;
    font-weight: bold;
    color: black !important;
   //padding: 4rem 0 !important;
     transform: translateY(0);
    position: relative;
    z-index: 10 !important;
    min-height: 270px;
    display: flex; /* Use flexbox for layout*/
  flex-direction: column; /*vertical  order  */
  //align-items: center; /*vertical layout center*/
  justify-content: center; /* Horizontal layout center*/

    a:link, a:visited {
      color: black !important;
      font-size: 11pt;
    }
    a:hover {
      color: #E41f2b !important;
      font-size: 11pt;
      text-decoration: none !important;
    }
  }

  .contact {
    font-weight: normal;
  }

  .red {
    color: #990000;
  }
  .text-decoration-none {
padding: 12px 0;
  }
</style>